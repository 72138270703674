<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="6" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <!--            <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Tìm kiếm" />-->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="{ name: constRouter.PARTNER_STORE.name }"
            >
              <span class="text-nowrap">Thêm đối tác</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :filter="filter"
        :items="fetchContracts"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
        @row-clicked="myHandler"
      >
        <!-- Column: mapping -->
        <template #cell(mapping)="data">
          <feather-icon
            v-if="data.item.mapping === null"
            icon="XIcon"
            size="16"
            class="align-middle text-danger"
          />
          <feather-icon v-else icon="CheckIcon" size="16" class="align-middle text-success" />
        </template>
        <!-- Column: user -->
        <template #cell(user)="data">
          {{ data.item.user && data.item.user.username }}
        </template>
        <!-- Column: active -->
        <template #cell(active)="data">
          <b-button
            v-if="data.item.allow_import === 1 || data.item.allow_import === true"
            v-b-modal.modal-primary
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            @click.stop="showActiveDialog(data.item)"
          >
            <span class="text-nowrap">Huỷ kích hoạt</span>
          </b-button>
          <b-button
            v-else
            v-b-modal.modal-primary
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            @click.stop="showActiveDialog(data.item)"
          >
            <span class="text-nowrap">Kích hoạt</span>
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            variant="flat-success"
            class="btn-icon rounded-circle"
            :to="{
              name: constRouter.PARTNER_VIEW.name,
              params: { id: data.item.id },
            }"
          >
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-tooltip title="Xem chi tiết" :target="`invoice-row-${data.item.id}-preview-icon`" />

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              :to="{
                name: constRouter.PARTNER_EDIT.name,
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Sửa</span>
            </b-dropdown-item>
            <b-dropdown-item @click.stop="showRemoveItemDialog(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!--modal-->
    <b-modal
      id="modal-primary"
      cancel-variant="outline-secondary"
      cancel-title="Huỷ bỏ"
      ok-title="Đồng ý"
      modal-class="modal-primary"
      centered
      :title="activeModalTitle"
      @ok="toggleActivePartner"
    >
      <b-card-text>
        {{ activeModalContent }}
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-primary2"
      v-model="removeDialog"
      cancel-variant="outline-secondary"
      :cancel-title="$t('Disagree')"
      :ok-title="$t('Agree')"
      modal-class="modal-primary"
      centered
      :title="$t('Delete {resource}', { resource: $t('partner') })"
      no-close-on-esc
      no-close-on-backdrop
      @ok="removeItem"
      @cancel="removeDialog = false"
      @hidden="removeDialog = false"
      @close="removeDialog = false"
    >
      <b-card-text>
        {{
          $t('Are you sure want to delete this {resource}?', {
            resource: $t('partner'),
          })
        }}
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardText,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { avatarText } from '@core/utils/filter';
import { numberWithCommas } from '@core/utils/utils';
import Ripple from 'vue-ripple-directive';
import { partnerRemove, partnerUpdateAllowImport } from '@/apis/apiPartner';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import constRouter from '@/constants/constRouter';
import useContractList from './usePartnerList';
import contractStoreModule from '../partnerStoreModule';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BCardText,
  },
  directives: {
    Ripple,
  },
  setup(props, { root }) {
    const USER_APP_STORE_MODULE_NAME = 'app-customer';
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, contractStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      }
    });
    const myHandler = (val) => {
      root.$router.push({ name: constRouter.PARTNER_VIEW.name, params: { id: val.id } });
    };
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      // filter
      statusFilter,
      typeFilter,
      statusOptions,
      typeOptions,
    } = useContractList();
    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchContracts,
      tableColumns,
      perPage,
      currentPage,
      sortBy,
      isSortDirDesc,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      avatarText,
      statusOptions,
      typeOptions,
      typeFilter,
      statusFilter,
      // UI
      resolveUserStatusVariant,

      numberWithCommas,
      myHandler,
    };
  },
  data: () => ({
    constRouter,
    filter: null,
    activeModalTitle: '',
    activeModalContent: '',
    activePartner: null,

    // dialog
    removeDialog: false,
  }),
  methods: {
    showRemoveItemDialog(item) {
      // show dialog
      this.removeDialog = true;

      // store item will remove
      // this.rmItems.push(item);
      this.rmItems = [item];
    },
    removeItem() {
      // turn of dialog
      this.removeDialog = false;
      // remove item from list
      this.rmItems.forEach((rmItem) => {
        // call api
        partnerRemove(rmItem.id)
          .then(() => {
            this.refetchData();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Xoá đối tác',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Xoá đối tác thành công',
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Xảy ra lỗi',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Xoá đối tác thất bại',
              },
            });
          });
      });

      // clear rmItems
      this.rmItems = [];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showActiveDialog(thePartner) {
      if (!(thePartner.allow_import === 1 || thePartner.allow_import === true)) {
        this.activeModalTitle = 'Kích hoạt đối tác';
        this.activeModalContent = 'Bạn có muốn kích hoạt đối tác này?';
      } else {
        this.activeModalTitle = 'Hủy kích hoạt đối tác';
        this.activeModalContent = 'Bạn có muốn hủy kích hoạt đối tác này?';
      }
      this.activePartner = thePartner;
    },
    toggleActivePartner() {
      const { name, allow_import: allowImport } = this.activePartner;
      // call api
      partnerUpdateAllowImport(
        {
          name,
          allowImport: !allowImport,
        },
        this.activePartner.id,
      )
        .then((thePartner) => {
          this.activePartner.allow_import = !allowImport;
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Xảy ra lỗi',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Cập nhật trạng thái thất bại',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
