import { partnerList } from '@/apis/apiPartner';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPartners(ctx, condition) {
      return new Promise((resolve, reject) => {
        partnerList(condition)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
